import React, { useState } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import useSite from '../hooks/use-site'
import PortableText from '../components/richText'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

export const query = graphql`
  query FaqPageQuery {
    faq: sanityFaq {
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
  }
`

const FaqStyles = styled.div`
  min-height: 70vh;

  .accordion {
    margin-bottom: 1rem;
    a {
      border-bottom: 1px dashed var(--black);
    }
    label {
      cursor: pointer;
      display: block;
    }
    input {
      display: none;
    }
  }

  .fade-enter,
  .fade-exit {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 800ms;
  }

  .fade-exit-active {
    opacity: 1;
    transition: opacity 400ms;
  }
`;

const Accordion = ({ answer, id, isChecked, question }) => {
  return (
    <div className="accordion">
      <label htmlFor={id}>{question}</label>
      <input id={id} type="radio" name="accordion" />

      <CSSTransition
        classNames="fade"
        in={isChecked}
        timeout={800}
      >
        {isChecked ? <section className="text-2xl"><PortableText blocks={answer} /></section> : <></>}
      </CSSTransition>
    </div>
  )
}

const FaqPage = ({ data }) => {
  const site = useSite()
  const faqData = data.faq._rawContent

  console.log(data.faq._rawContent)

  const faqElements = faqData.map(({ _key, answer, question }) => {
    return <Accordion key={_key} answer={answer} id={_key} isChecked={false} question={question} />
  });

  const [elements, setElements] = useState(faqElements);

  const handleChange = ({ target }) => {
    setElements(elements.map(el => {
      const { key, props } = el;

      if (key === target.id) return { ...el, props: { ...props, isChecked: true } };
      else return { ...el, props: { ...props, isChecked: false } }
    }))
  }

  return (
    <Layout scroll="true">
      <SEO title={site.title} description={site.description} />
      <FaqStyles className="serif w-full h-full flex justify-center items-center text-center">
        <div className="max-h-full px-3 lg:w-6/12" onChange={handleChange}>
          {elements}
        </div>
      </FaqStyles>
    </Layout>
  )
}

export default FaqPage
